<section class="top_site_main " style="min-height: 180px; color: #ffffff;background-image:url(https://senguptasresearchacademy.com/wp-content/uploads/2017/05/literature.png);">
    <div class="container">
       <div class="row">
          <h1 class="tes-sec" style="font-size: 44px;">Manuscript Editing Services, Clinical Biostatistics Service</h1>
       </div>
    </div>
 </section>
 <section>
    <div class="container">
       <div class="row justify-content-center">
           <div class="col-md-6">
            <iframe src="https://player.vimeo.com/video/220681213" width="100%" height="360" frameBorder="0" allowfullscreen="allowfullscreen">
            </iframe>
           </div>
       </div>
       <div class="row justify-content-center">
        <div class="col-md-7">
            <img src="assets/user/assets/img/Academy-Services-768x432.png" class="w-100">
        </div>
       </div>
       <div class="row">
        <h3 style="color:#000000; font-weight: 700;" class="title">RESEARCH SERVICES</h3>
        <div class="line"></div>
        <div>
            <h4 style="color: #283891; text-align: left;  font-weight: 700; font-style: normal;  font-size: 16px;
            margin-top: 20px;">SENGUPTA'S RESEARCH ACADEMY PROVIDES ASSISTANCE WITH PREPARATION OF ALL MAJOR TYPES OF MANUSCRIPTS INCLUDING :</h4>
            <ul>
                <li>Randomized controlled trials (RCT),</li>
                <li>Original articles that are not RCTs,</li>
                <li>Original articles that are not RCTs,</li>
                <li>Review articles,</li>
                <li>Case reports,</li>
                <li>Letter to editor</li>
            </ul>
        </div>
       </div>
       <div class="row">
        <div class="col-md-12">
            <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <h4 class="accrodian-h4">MANUSCRIPT CONTENT EDITING & REVIEW</h4>
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        Improve sentence structure, style of writing and clarity in the message. Also receive high quality review and comments to improve the paper. You will specifically receive comprehensive editing of the entire paper including tips on improving the introduction, methods, results and discussion.<b>You will also receive other assistance</b> such as journal recommendations, target journal formatting and answering reviewer comments
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <h4 class="accrodian-h4">BIOSTATISTICS (using STATA software)</h4>
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>Receive appropriate statistical analysis from a clinical perspective that will bring out the best possible results from your data. Availing this service will give you the results section of your manuscript including the write up, tables and appropriate figures. Specific services provided are as follows:</p>
                        <ul>
                            <li>Help for Excel sheet (data sheet) creation</li>
                            <li>Data cleaning procedures to ensure internal validity</li>
                            <li>Statistical analysis of data to answer study questions</li>
                            <li>Making meaningful tables for representing data</li>
                            <li>Making relevant graphs (box plots, scatter plots with LOWESS curves etc.)</li>
                            <li>Sample Size Calculation, post hoc analysis for power of study</li>
                        </ul>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      <h4 class="accrodian-h4">REVIEW ONLY</h4>
                    </button>
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        State of the art review of the highest quality will be provided for the paper. The review will be comparable to industry standards set for the peer review process by most international journals and will conform to the standards set by the ICMJE. This service does not include content editing.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingfour">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                        <h4 class="accrodian-h4">RESEARCH PROPOSAL & STUDY DESIGN</h4>
                      </button>
                    </h2>
                    <div id="collapsefour" class="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        Receive recommendations on the best study design for answering your particular study question. Specifically, you will receive help with review of the protocol, ethical considerations, sample size estimation and statistical plan
                      </div>
                    </div>
                  </div>
              </div>
        </div>
       </div>
       <div class="row mt-4">
        <h3 style="color:#000000; font-weight: 700;" class="title">HOW IT WORKS</h3>
        <div class="col-md-12">
            <div class="service_work_content">
                Please send your requests to <a href="#">info@senguptasresearchacademy.com</a>mentioning the service required.
            </div>
            <div class="service_work_content">
                Different charges for services are applicable depending on the type of manuscript, the word count and the initial quality of the paper.
            </div>
            <div class="service_work_content">
                A recommendation on the best service will be provided within 48 hours after paper is sent. A quotation of the charges will be sent to you accordingly.
            </div>
            <div class="service_work_content">
                Typical turn over time will be 6 weeks (+/- 7 days) from the date of agreement of the charges. We will also provide technical support including assistance with revisions asked by reviewers (includes any reanalysis if indicated) within 6 months of delivery of the manuscript to you. Any support after 6 months will be chargeable as per our terms and conditions.
            </div>
            <div class="service_work_content">
                Sengupta’s research academy does not provide assistance with preparing protocols for specific Funding agencies (viz. Indian Council of Medical Research (ICMR), National Institute of Health (NIH) etc.).
            </div>
            <div class="service_work_content">
                We do not assist with journal submission process. We do not accept authorship for any paper but request an acknowledgement for our support.
            </div>
            <div class="service_work_content">
                We also do not include Plagiarism checks but if you opt for content editing and review, your paper will be typically molded in a manner where plagiarism will not be a consideration
            </div>
            <div class="service_work_content">
                Disclaimer: The academy does not guarantee publication of your manuscript in any journal, whether peer reviewer/Indexed or not. We do not take responsibility if the paper is not published going forward. The publication potential is solely the author’s responsibility to decide, though we can assist with it.
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-12">
                <p>Our publication track record so far – Processed 150+ manuscripts and 53 Thesis so far, helped publish 100+ articles</p>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-10">
                <img src="assets/user/assets/img/100-Publications.jpg" class="w-100">
            </div>
        </div>
       </div>
    </div>
</section>