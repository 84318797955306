 <div class="main-wrapper">

    <div class="content" style="background: #f5f5f5;padding: 50px 0;">
       <div class="container">
         <div class="row justify-content-center" *ngIf="loading">
            <div class="col-md-3">
                <img src="assets/user/assets/img/loader/spinner2.gif" />
            </div>
         </div>
          <div class="row justify-content-center" *ngIf="!loading">
            <div class="col-md-7">
               <div class="order-header">
                  <h3 class="wizard-header">CHECKOUT</h3>
               </div>
               <div class="order-body">
                  <div class="row">
                     <div class="col-md-12">
                        <div class="order-list">
                           <ul>
                              <li *ngFor="let data of modules">
                                 <div class="order-list-details">
                                    <h4>{{ data.course_name }}</h4>
                                 </div>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-md-12 col-sm-12">
                        <form #couponForm="ngForm" (ngSubmit)="couponSubmit(couponForm.value)"> 
                            <div class="no-edges row pt-4 pb-4">
                                <div class="col-md-4">
                                    <h5>Have Any Coupon ?</h5>
                                </div>
                                <div class="col-md-4">
                                    <input type="text"  style="width: 100%; height: 32px ;border: dotted 1px #f25c04 !important;" required ngModel #coupon="ngModel" name="coupon_code">
                                </div>
                                <div class="col-md-4">
                                    <button type="submit" class="btn btn-dark" style="padding: 4px 10px; font-size: 14px; display: block; margin: 0 auto;">Apply Coupon</button>
                                </div>
                            </div>
                        </form>
                     </div>
                  </div>
                  <div class="row mt-3 mb-3">
                     <div class="col-md-12">
                        <div class="total-pr">
                           <div class="order-list-details">
                              <h5><b>TOTAL</b></h5>
                           </div>
                           <div class="order-list-price"><h5>{{ price }}/-</h5></div>
                        </div>
                     </div>
                  </div>
                  <div class="row justify-content-center">
                     <div class="col-md-6">
                        <button (click)="buyCourse()" class="btn-form-func">Continue Order</button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
       </div>
    </div>
</div>
