import { Component, OnInit } from '@angular/core';
import { UserService } from "../../service/user.service";
@Component({
  selector: 'app-grant',
  templateUrl: './grant.component.html',
  styleUrls: ['./grant.component.css']
})
export class GrantComponent implements OnInit {

  public response:any;
  public grant:any;
  constructor(private UserService:UserService) { }

  ngOnInit(): void {
    this.UserService.getGrant().subscribe((success:any)=>{
      this.response = success;
      this.grant = this.response.data;
    });
  }

}
