import { Component, OnInit } from '@angular/core';
import { UserService } from "../../service/user.service";
import { Router, ActivatedRoute } from "@angular/router";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { environment } from "../../../environments/environment";
import { WindowRefService } from '../../service/window-ref.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {

  public loading : any = true;
  public response :any;
  public user:any;
  public modules:any;
  public price:any = 0;
  public coupon_code:any = "";
  public is_free:boolean = false;

  constructor(private winRef: WindowRefService, private UserService:UserService, private Router:Router, private ActivatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.UserService.getPrice().subscribe((success:any)=>{
      this.loading = false;
      this.response = success;
      this.price = this.response.data.price;
    });

    this.UserService.getAllCourse().subscribe((success:any)=>{
     this.response = success;
     if(this.response.code == 200){
      this.modules = this.response.data;
     }
    });
  }

  buyCourse = () =>{
    var coupon_code = this.coupon_code;
    if(localStorage.getItem('user')){
      this.user = JSON.parse(localStorage.getItem('user'));
      var data = {
        user_id : this.user.id,
        coupon_code:coupon_code,
        price:this.price
      }
      this.loading = true;
      this.UserService.buyCourse(data).subscribe((success)=>{
        this.response = success;
        this.loading = false;
        if(this.response.code == 200){
          if(this.is_free){
            Swal.fire({
              icon: 'success',
              text: 'You have successfully bought this course..'
            }).then(()=>{
              this.Router.navigate(['/e-learning-course']);
            })
          }else{
            // For PAY YOU MONEY
            
            // window.location.href = `${environment.web_url}payu-money-payment?coupon_id=itwkaaclthjukqqigigldeejzvrhkqwavdqwxwnqdfgbaxrbufomzyduqlehopvgsmmikpmsdiphejaikkuzvikfzwdyseaduznjsuzayiiqvtpzgeofuxbmtxuqgsnvgmkmrfjeaudtuojpgracesnsmgjylbwk&data=${this.response.price}`;
            // window.location.href = `http://localhost:8000/payu-money-payment?coupon_id=itwkaaclthjukqqigigldeejzvrhkqwavdqwxwnqdfgbaxrbufomzyduqlehopvgsmmikpmsdiphejaikkuzvikfzwdyseaduznjsuzayiiqvtpzgeofuxbmtxuqgsnvgmkmrfjeaudtuojpgracesnsmgjylbwk&data=${this.response.price}`;
            
            // For RAZORPAY
            this.payWithRazor(this.response.price, this.response.order_id, this.response.api_order_id);
          }
          
        }else{
          console.log(this.response);
        }
      });
    }else{
      this.Router.navigate(['login']);
    }
  }

  payWithRazor(price, order_id:any, api_order_id) {
    const options: any = {
      // key: 'rzp_test_zxg9nuoyBcIMFq',
      key: 'rzp_live_Ol54zdXadjKja3',
      //amount: parseInt(price) * 100, // amount should be in paise format to display Rs 1255 without decimal point
      amount: price * 100, // amount should be in paise format to display Rs 1255 without decimal point
      currency: 'INR',
      name: 'Sengupta Research Academy', // company name or product name
      description: 'Payment For Buying Course',  // product description
      image: 'https://senguptasresearchacademy.com/assets/user/assets/img/logo.png', // company logo or product image
      order_id: api_order_id, // order_id created by you in backend
      payment_capture :1,
      prefill: {
        name: this.user.name,
        email: this.user.email,
      },
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      notes: {
        // include notes if any
      },
      theme: {
        color: '#0c238a'
      }
    };

    options.handler = ((response, error) => {
      this.UserService.updateOrderStatus({order_id : order_id, transaction_id : response.razorpay_payment_id}).subscribe((data:any)=>{
        console.log(data);
        Swal.fire({
          icon: 'success',
          text: 'You have successfully bought this course..'
        }).then(()=>{
          this.Router.navigate(['/e-learning-course']);
        })
      });
    });

    options.modal.ondismiss = (() => {
      // handle the case when user closes the form while transaction is in progress
      console.log('Transaction cancelled.');
    });

    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();
  }

  couponSubmit = (formValue:any) =>{
   this.coupon_code = formValue.coupon_code;
    this.UserService.checkCoupon(formValue).subscribe((success:any)=>{
      this.response = success;
      this.price = success;
      if(success == 2499){
        Swal.fire({
          icon: 'error',
          text: 'Coupon Code not Matched..'
        })
      }else if(success == 0){
        this.is_free = true;
        Swal.fire({
          icon: 'success',
          text: 'Discount Added..'
        })
      }else{
        Swal.fire({
          icon: 'success',
          text: 'Discount Added..'
        })
        this.is_free = false;
      }
     });
  }

}
