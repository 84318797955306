<div class="main-wrapper">

    <div class="content" style="background: #f5f5f5;padding: 50px 0;">
       <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-6">
                <img src="assets/user/assets/img/login.jpg" class="d-block w-100">
            </div>
            <div class="col-md-6 login-right">
               <div class="row justify-content-center mt-5">
                  <div class="col-md-9">
                     <div class="login-header">
                        <h5 class="text-center mb-4">Reset Password</h5>
                         <!-- <h3>Login <a href="instructors-login.php">Are you a Instructor?</a></h3> -->
                         <form #resetForm="ngForm" (ngSubmit)="resetPassword(resetForm.value)">
                           
                            <input type="password" class="form-control mt-4" placeholder="Password" required ngModel #password="ngModel" name="password">
                           <div class="error" *ngIf="password.errors && password.touched">
                             <p class="error-forms" *ngIf="password.errors?.required">Password is required</p>
                           </div>

                           <input type="password" class="form-control mt-4" placeholder="Re Password" required ngModel #re_password="ngModel" name="re_password">
                           <div class="error" *ngIf="re_password.errors && re_password.touched">
                             <p class="error-forms" *ngIf="re_password.errors?.required">Re Password is required</p>
                           </div>

                           <div class="error" *ngIf="error">
                            <p class="error-forms">Password And Confirm Password Not Matched...</p>
                          </div>

                         <input type="submit" class="button button-primary button-login w-100">
                         <p class="text-center mt-2">Already A Member ? <a routerLink="/login" style="font-size: 17px; color: #48421f;">Login</a></p>
                        </form>
                    </div>
                  </div>
               </div>
            </div>
          </div>
       </div>
    </div>
</div>