<section class="top_site_main " style="min-height: 180px; color: #ffffff;background-image:url(https://senguptasresearchacademy.com/wp-content/uploads/2017/05/literature.png);">
    <div class="container">
       <div class="row">
          <h1 class="tes-sec" style="font-size: 44px;">Online Clinical Research Fellowship Course</h1>
       </div>
    </div>
 </section>
 <section>
     <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-9">
               <img src="assets/user/assets/img/follow-up.png" class="w-100">
            </div>
            <div class="col-md-9 mt-4">
                <img src="assets/user/assets/img/follow-up2.png" class="w-100">
             </div>
        </div>
        <div class="row justify-content-center mt-4">
            <div class="col-md-6">
                <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fdrsunny1980%2Fvideos%2F691858564963505%2F&show_text=false&width=560" width="100%" height="300" frameBorder="0" allowfullscreen="allowfullscreen">
                </iframe>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <p>Hi there !</p>
                <p><a href="#">Greetings from Sengupta’s Research Academy.</a> Welcome to this page offering a “one-of-its-kind” and “hands-on” totally online fellowship course specifically designed for ophthalmologists to learn the art of research and publications. However examples will help anyone interested in research.</p>
                <p>You will receive online learning material and you can do the assignments at your own pace. Enroll from any part of the world and utilize your time to upgrade your research skills.</p>
                <p>Specifics are given in the attached brochures above. In summary:</p>
                <p><span style="color: #0000ff;">Step 1:</span> Gaining knowledge by watching the 3hr lecture series over the first week. Enrollment is part of the total cost to you.</p>
                <p><span style="color: #0000ff;">Step 2:</span>  <b>Assignment (stats and results) – </b>You will receive an Excel from which you will have to make the results section including an outline of the tables and figures. You will receive enough guidance when you are doing it. Then finally you will receive the ideal results section to compare with what you have done.</p>
                <p><span style="color: #0000ff;">Step 3:</span> <b>One on one interaction with Dr Sengupta</b> on a ZOOM Call, where he will discuss a journal club article with you and teach you how to dissect and critique a paper. </p>
                <p><span style="color: #0000ff;">Step 4:</span> <b>(Manuscript writing)</b> – In manuscript preparation part, you will get the study concept and you will have to write the paper. Then you will receive the ideal manuscript.</p>
                <p><span style="color: #0000ff;">Step 5:</span><b>(Your own Manuscript preparation – available with Rs 20,000 full fellowship only)</b> – This involves working with your own data, making your own results and writing your own manuscript from start to finish, based on everything you learn in this course. Sengupta’s research academy will review your finished manuscript and provide guidance in order to improve it and bring it up to journal submission standards. You can upgrade to this package later, after you finish the hands on course and are satisfied. Or you can enroll together right now. The choice is entirely yours. </p>
                <p><span style="color: #0000ff;">Step 6:</span> <b>Continue to learn by getting access to our special facebook page</b> exclusively for clinical research fellows. Here, we discuss journal articles, share our research experiences, have periodic Q&A sessions, Live journal clubs and so much more Please see the brochures above for more details. These assignments will be hands on training that take you from from the excel sheet to the full paper in a month. And RELAX! You can do this at your own pace</p>
                <p>Please see the brochures above for more details. These assignments will be hands on training that take you from from the excel sheet to the full paper in a month.<span style="color: #0000ff;"> And RELAX! You can do this at your own pace</span></p>
            </div>
            <div class="col-md-12">
                <u><b>Payment details</b></u>
                <ul style="list-style-type: decimal;">
                    <li><b><i>Indian participants</i></b> enrolling for <u>Crash Course</u>: <span class="red">Rs 5500,</span> <b>(includes step 1, 2 and 4 only)</b>. Live Interaction with Dr Sengupta is not included in this. To pay and enroll, click <a href="{{ fellowship.crash_course }}">here.</a></li>
                    <li><b><i>Indian participant</i></b> enrolling for<u>Shorter course</u> <b><u>(Includes Steps 1-4 & 6):</u></b><span class="red">Rs 10,300</span> (inclusive of online processing fees). To pay and enroll, <a href="{{ fellowship.shorter_course }}">here</a>. </li>
                    <li><b><i>Indian participant</i></b> enrolling for<u> Full course</u> <u><b>(Includes Steps 1-6):</b></u> <span class="red">Rs 20,500</span> (inclusive of online processing fees). To pay and enroll, click <a href="{{ fellowship.full_course }}">here</a>.  </li>
                    <li><i><b>International participant</b></i> (Outside India): Click <a href="{{ fellowship.international_1 }}">here</a> and complete payment <span class="red">(142$ USD)</span> to start enrollment <b>(Includes Steps 1 – 4 & 6)</b></li>
                    <li><b><i>International participant</i></b> (Outside India): Click <a href="{{ fellowship.international_2 }}">here</a> and complete payment <span class="red">(300$ USD)</span> to start enrollment <b>(Includes Steps 1 – 6)</b></li> 
                </ul>
                <p>Once you pay, please forward the payment receipt (an email) to <a href="mailto:info@senguptasresearchacademy.com">info@senguptasresearchacademy.com</a>  or Whatsapp/Call 9943909766. We will confirm your payment and then you will start receiving course material within one hour.</p>
                <p>I hope many of you will take advantage of this and take the next step towards research and publications.</p>
            </div>
        </div>
        <div class="row justify-content-center mb-4">
            <div class="col-md-6">
                <video width="100%" controls>
                    <source src="assets/user/assets/img/300_Fellows.mp4" type="video/mp4">
                    <source src="assets/user/assets/img/300_Fellows.ogg" type="video/ogg">
                </video>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-3">
                <div>
                    <figure>
                        <img src="assets/user/assets/img/research-passport.png" class="w-100">
                        <figcaption class="text-center director-a">Dr Sabyasachi Sengupta, <br> Director, Sengupta’s research academy</figcaption>
                        
                      </figure>
                </div>
            </div>
        </div>
     </div>
 </section>