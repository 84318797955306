<section class="top_site_main " style="min-height: 180px; color: #ffffff;background-image:url(https://senguptasresearchacademy.com/wp-content/uploads/2017/05/literature.png);">
    <div class="container">
       <div class="row">
          <h1 class="tes-sec">  Workshop </h1>
       </div>
    </div>
 </section>
 <section>
     <div class="container">
         <div class="row justify-content-center">
             <span [innerHtml]="workshop.description | safeHtml"></span>
         </div>
     </div>
 </section>
 