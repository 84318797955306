<section style="padding: 0px;">
   <div class="container-fluid" style="padding:0px">
      <div class="row">
         <img src="assets/user/assets/img/About-Us-banner.png">
      </div>
   </div>
</section>
<!-- End Hero -->
<!-- ======= Portfolio Section ======= -->
<section>
   <div class="container">
      <div class="row" data-aos="fade-up" data-aos-delay="100">
         <div class="col-lg-12 d-flex justify-content-center">
            <ul  class="nav nav-pills mb-3 portfolio-flters" id="pills-tab"  role="tablist">
               <li class="nav-item" role="presentation">
                  <button class="nav-link active nav-link-tab" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">DR. SENGUPTA</button>
               </li>
               <li class="nav-item" role="presentation">
                  <button class="nav-link nav-link-tab" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">OUR GENESIS</button>
               </li>
               <li class="nav-item" role="presentation">
                  <button class="nav-link nav-link-tab" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">THE ACADEMY</button>
               </li>
            </ul>
         </div>
         <div class="col-md-12">
            <div class="tab-content" id="pills-tabContent">
               <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                  <h4 class="about-heading">Dr. Sengupta’s Research Background and Expertise:</h4>
                  <p>Dr. Sabyasachi Sengupta is an ophthalmologist and retina specialist based in Mumbai and has been involved with clinical research since 2005, ever since he began his residency in Ophthalmology at the prestigious Jawaharlal Nehru Institute of Postgraduate Medical Education and Research (JIPMER, Pondicherry). He continued his residency going on to become a Diplomate of National board from Aravind Eye Hospital, Pondicherry, India and was awarded a gold medal by the National Board of Examinations. Despite having a rigorous clinical schedule, Dr. Sengupta was successful in publishing 20 papers in peer-reviewed journals during his residency. Following his residency, Dr. Sengupta went on to do a research & clinical fellowship at the renowned Sankara Nethralaya, where he was simultaneously involved in patient care and clinical research and was successful in publishing 12 papers over a two-year period.</p>
                  <p>Dr. Sengupta then went on to do a postdoctoral research fellowship at the world-renowned Wilmer Eye Institute at Johns Hopkins University School of Medicine, Baltimore, MD, USA. He also did a one-year course in Biostatistics at the Bloomberg School of Public Health, Johns Hopkins University. During his stint at Hopkins, Dr. Sengupta was exposed to a very systematic and organized way of doing research. He was fortunate to be mentored by very experienced researchers at Hopkins. This experience made Dr. Sengupta well versed with the infrastructure and support required to conduct world-class clinical research, which he aspires to catalyze in India and the rest of the developing world.</p>
                  <p>On his return to India from Johns Hopkins, Dr. Sengupta served as Head of Research at Aravind Eye Hospital, Pondicherry for over 2 years. While at Aravind, he was able to streamline and galvanize the research department, including defining roles for research coordinators, data entry personnel and clerical staff. He is also credited with establishing the Ethics Committee at Aravind, Pondicherry as per the Drugs Controller General of India guidelines. At Aravind, Dr. Sengupta mentored many aspiring physicians in designing and conducting their own studies and ultimately publishing their papers. The publication productivity of Aravind, Pondicherry grew under the influence of Dr. Sengupta’s efforts. Lastly, he was successful in forming high value collaborations with Wilmer Eye Institute, Johns Hopkins, Kellogg Eye Center, University of Michigan and The Camera Culture Group, MIT.</p>
                  <p>He has also been an invited speaker in many different research oriented forums, including the Indian Journal Of Ophthalmology Session at the All India Ophthalmic conference and other Research Methodology workshops.</p>
                  <p>Till date, Dr. Sengupta has published more than 85 papers in top rated peer-reviewed journals including Ophthalmology, JAMA Ophthalmology, American Journal of Ophthalmology, Eye and the British Journal of Ophthalmology. He also serves as a reviewer for all the above-mentioned journals and many other journals as well. He is currently the Associate Editor of the Indian journal of ophthalmology. He is part of the international reviewer board for the Research Council and Grant Review Board, Health and Medical Research Fund, Government of Hong Kong. Additionally, he serves on the research and advisory board of medical start up companies in his endeavor to forge successful academia – industry partnerships.</p>
                  <p>Having experienced different clinical and research settings during his training, including governmental (JIPMER), non – profit organizations (Aravind and Sankara Nethralaya) and arguably the best research organization globally (Hopkins), Dr. Sengupta feels well equipped to offer tailor – made research solutions for eager clinicians from diverse backgrounds.</p>
               </div>
               <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                  <h4 class="about-heading">Sengupta’s Research Academy – Genesis</h4>
                  <p><b>The Need:</b>The Need for performing high quality medical research has been felt by medical professionals for over a century. Advances in clinical research have led to changing paradigms in disease management termed as “evidence based medicine”.<br>
                     Clinicians in the developing world are realizing the need for developing their own practice patterns based on their own clinical research. Evidence generated and applied locally will go a long way in improving patient outcomes in a more equitable manner.
                  </p>
                  <p><b>The lacunae:</b> The medical fraternities in the developing world lack adequate basic training in “research techniques” during their undergraduate and postgraduate education as opposed to their counterparts in the developed world, who are taught these techniques from a very early age. Given the intense desire to perform and participate in research projects and publish papers, this lack of proper training creates a big hurdle.</p>
                  <p><b>The Solution:</b> The need of the hour is to create a dedicated web based research portal that provides a complete solution including educating researchers via an e-learning platform and providing them with technical assistance in conducting their own research. Dr. Sengupta and his team, with their decade long expertise in research and publications, will provide this much needed support in a tailor-made fashion, keeping in mind the resources and limitations of each individual researcher.</p>
                  <a href="#">You can see our key performance metrics here.</a>
               </div>
               <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                  <h4 class="about-heading">About Sengupta’s Research Academy:</h4>
                  <p>Sengupta’s Research Academy is the first web-based portal dedicated to different aspects of medical research under one roof. Established in 2017, it features the first of its kind e-learning portal where concepts of research are laid out in a systematic and stepwise manner such that learning is enjoyable and productive. Additionally, we also provide technical assistance for different aspects of medical research, making Sengupta’s Research Academy a complete research solution, a one-stop shop for learning and doing research in India and the developing world at large. <a href="#">You can see our key performance metrics here.</a> The salient features of the academy are:</p>
                  <p><b>Education: </b>A series of 20 online lectures divided into five modules covering topics such as literature review, how to choose a good research topic, how to prepare an excel sheet, how to communicate with a statistician, data management and archiving, basics of statistics, basics of manuscript writing and reference management tools. Watch the videos online at any time on any device and learn at your own pace. Each video is approximately 10 minutes long and is packed with information in a very lucid manner.</p>
                  <p><b>Continued Technical Support:</b> After learning about doing research the right way, you can avail technical support from the academy for planning and study design, ethics committee and institutional review board applications, data recording in excel, data cleaning and statistical analysis, manuscript writing, review of manuscripts, editing, and reference management. We have recently completed 100 assisted publications for our patrons in some of the top peer – reviewed and indexed ophthalmic journals</p>
                  <p>Some of the activities of the Research Academy are:</p>
                  <ul style="list-style-type: auto;">
                     <li><b>Online lectures</b> on research methodology.</li>
                     <li><b>Online clinical research fellowship –</b> A unique hands on research fellowship to have practical experience of manuscript preparation.</li>
                     <li><b>Research LIVE:</b> Interviews with some of the best Indian researchers – Get inspired by their story (On Facebook page and YouTube Channel)</li>
                     <li><b>Research Bites:</b> Small, bite sized lectures to understand core concepts of clinical research. Streaming on Facebook and YouTube channels of Senguptas research academy.</li>
                     <li><b>Research MasterClass:</b> Facebook Live sessions based on what you want to hear.</li>
                     <li><b>Research Collaborations:</b> Getting Indian ophthalmic researchers together in an attempt to enhance collaborations.</li>
                     <li><b>Research Internship: </b>An opportunity to work on live projects at the research academy.</li>
                  </ul>
                  <p>There are other programs from the academy in the pipeline such as research funding and other lecture modules on ethics in research, GCP etc..</p>
                  <p>Some of the activities are summarized in this chart.</p>
                  <div class="row justify-content-center">
                     <div class="col-md-6">
                        <img src="assets/user/assets/img/Academy_Activities-600x849.png" class="w-100">
                     </div>
                  </div>
                  <div class="row mt-4 justify-content-center">
                    <div class="col-md-8">
                     <iframe src="https://player.vimeo.com/video/711067465?h=ef5e49cc0c" width="100%" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                    </div>
                 </div>
               </div>
            </div>
         </div>
      </div>
   </div>