<section class="top_site_main " style="min-height: 180px; color: #ffffff;background-image:url(https://senguptasresearchacademy.com/wp-content/uploads/2017/05/literature.png);">
    <div class="container">
       <div class="row">
          <h1 class="tes-sec">PUBLICATION</h1>
       </div>
    </div>
 </section>
 <!-- ======= Portfolio Section ======= -->
 <section>
    <div class="container">
       <div class="row">
          <div class="col-md-12">
             <p><u><b>Peer reviewed Publications to My Credit:</b></u></p>
             <ul class="pulication-sec">
                <li><strong><u>Sengupta S,</u></strong> Venkatesh R, Ravindran RD. Safety and efficacy of using off Label Bevacizumab versus Mitomycin C to Prevent Bleb Failure in a Single Site Phaco trabeculectomy by a Randomized Controlled Clinical Trial. J Glaucoma. 2012 Sep;21(7):450-9</li>
                <li><strong><u>Sengupta S,</u></strong> Chang DF, Gandhi R, Kenia H, Venkatesh R. Incidence and long term outcomes of toxic anterior segment syndrome (TASS) at Aravind Eye Hospital. J Cataract Refract Surg 2011; 37: 1673 – 78.</li>
                <li>Ravindran RD, Venkatesh R, Chang DF, <strong><u>Sengupta S,</u></strong> Gyatsho J, Talwar B. The rate of post-cataract endophthalmitis at an Aravind Eye Hospital: Outcomes from more than 42,000 consecutive cases using standardized sterilization and prophylaxis protocols. J Cataract Refract Surg. 2009; 35: 629-36.</li>
                <li><strong><u>Sengupta S,</u></strong> Subramoney K, Srinivasan R,Nongrum B, Agarwal V, Pandian DG, Suchismitha T, Kaliaperumal S. Use of a mydriatic cocktail with a wick for preoperative mydriasis in cataract surgery: a prospective randomized controlled clinical trial- Eye 2010;24:118–122</li>
                <li>Venkatesh R, Tan CSH, <strong><u>Sengupta S,</u></strong> Ravindran RD, Krishnan T, Chang DF Phacoemulsification versus manual small-incision cataract surgery for white cataract. J Cataract Refract Surg 2010; 36:1849–1854</li>
                <li><strong><u>Sengupta S,</u></strong> van Landingham SW, Solomon SD, Do DV, Friedman DS, Ramulu PY. Driving habits in older patients with central vision loss. Ophthalmology 2014 Mar;121(3):727-32.</li>
                <li>Sengupta S. Combined Branch retinal Artery and Vein Occlusion in Hyperhomocysteinemia. JAMA Ophthalmol. 2014; 132(10):1255</li>
                <li>Loprinzi PD, Brodowicz GR, <strong><u>Sengupta S,</u></strong> Solomon SD, Ramulu PY. Accelerometer-assessed physical activity and diabetic retinopathy in the United States. JAMA Ophthalmol. 2014; 132(8): 1017-9.</li>
                <li>Rishi E, Rishi P, <strong><u>Sengupta S,</u></strong> Jambulingam M, Madhavan HN, Badrinath SS, Gopal L, Therese L. Acute post-operative Bacillus cereus endophthalmitis mimicking Toxic Anterior Segment Syndrome. Ophthalmology. 2013 Jan; 120(1):181-5.</li>
               <li>Venkatesh R, Ravindran RD, Bharathi B, Sengupta S. Optic nerve cysticercosis. Ophthalmology. 2008; 115: 2094.</li>
               <li>Kalaiselvi G, Narayana R, Krishnan T, Sengupta S. Intrastromal voriconazole for deep recalcitrant fungal keratitis: a case series. Br J Ophthalmol. 2015 Feb;99(2):195-8.</li>
               <li>Priya A, Veena K, Thulasiraj R, Fredrick M, Venkatesh R, <strong><u>Sengupta S,</u></strong> Bassett K. Vision Screening by Teachers in Southern Indian Schools: Testing a New ‘All Class Teacher’ Model. Ophthalmic 2015;22(1):60-5.</li>
               <li>Sengupta S. Shukla D, Ramulu P, Natarajan S, Biswas J. Publish or perish – The art of scientific writing. Indian J Ophthalmol. 2014 Nov;62(11):1089-1093.</li>
               <li><strong><u>Sengupta S,</u></strong> Nguyen AM, Landingham SW, Solomon SD, DO DV, Ferrucci L, Friedman DS, Ramulu PY. Evaluation of real-world mobility in age-related macular degeneration. BMC Ophthalm. 2015; 15:9 (Highly Accessed Publication).</li>
               <li><strong><u>Sengupta S,</u></strong> Krishnakumar S, Sharma T, Gopal L, Khetan V. Histopathology of Retinoblastoma: Does standardization make a difference in reporting?. Pediatric blood and Cancer – 2013 Feb;60(2):336-7</li>
               <li>John S, <strong><u>Sengupta S,</u></strong> Reddy SJ, Prabhu P, Kirubanandan K, Badrinath SS. The Sankara Nethralaya Mobile Teleophthalmology Model for comprehensive eye care delivery in Rural India – Telemed J E Health. 2012; 18: 382-7</li>
               <li>Sengupta S, Krishnakumar S, Biswas J, Gopal L, Khetan V. Fifteen year Trends in Indications of Enucleation from a tertiary care centre in South India – Indian J Ophthalmol 2012; 60: 179 – 82</li>
               <li>Venkatesh R, ChangDF, Muralikrishnan R, Kenia H, Gogate P, Sengupta S. Manual Small Incision Cataract Surgery: A Review. Asia-Pac J Ophthalmol. 2012; 1: 113 – 119</li>
               <li><strong><u>Sengupta S,</u></strong> Krishnan T, Ravindran RD, Vaitilingam MC. Changing referral patterns of infectious corneal ulcers to a tertiary care facility in South India – 7 year analysis – Ophthalmic Epidemiol. 2012; 19: 297-301</li>
               <li>Venkatesh R, <strong><u>Sengupta S,</u></strong> Robin AL. Mitomycin C Augmented Trabeculectomy Combined with Single Site Manual Small Incision Cataract Surgery through a Tunnel Flap Technique. Asia-Pac J Ophthalmol 2012;1: 142-146</li>
               <li>Rishi E, Rishi P, Gopal L, Sharma T, Sengupta S. Submacular Hemorrhage – A study amongst Indian Eyes – Indian J Ophthalmol – 2012 Nov-Dec;60(6):521-5.</li>
               <li>Gupta A, Srinivasan R, Kaliaperumal, Sengupta S. Pars Plana Vitrectomy for Macular detachment with Optic Disc Pit. S Asian J Ophthalmology 2006; 8:153-155.
               </li>
               <li>Subashini K, G Arvind, S Sabyasachi, S Renuka. Stromal Abscess caused by Enterococcus faecalis: An unusual presentation. Indian J Ophthalmol 2007; 55: 160-161</li>
               <li>Kaliaperumanl S, Gupta A, <strong><u>Sengupta S,</u></strong> Srinivasan R. Unilateral Hemorrhagic keratouveitis as an Initial presentation of Takayasu’s Arteritis. Indian J Ophthalmol 2007: 55; 397-398.</li>
               <li>Suchi ST, Gupta A, <strong><u>Sengupta S,</u></strong> Srinivasan R. Chronic bilateral lacrimal gland pseudotumor in an adult. Indian J Ophthalmol 2008; 86-87
               </li>
               <li>Krishnan T, <strong><u>Sengupta S,</u></strong> Reddy PR, Ravindran Secondary Pseudomonas infection of Fungal Keratitis Following Use of Contaminated Natamycin Eye Drops- A case series. Eye. 2009; 23: 477-9.</li>
               <li><strong><u>Sengupta S,</u></strong> Dhanapal D, Ravindran RD, Devi N. Cerebral blindness following scorpion sting. Journal of Neuro-Ophthalmology,  2009; 29: 154-155</li>
               <li><strong><u>Sengupta S,</u></strong> Gandhi NM, Ravindran RD. Abducent nerve palsy following spinal anesthesia- A case report. Asian J Ophthalmol 2009; 11: 130-1.</li>
               <li><strong><u>Sengupta S,</u></strong> Ravindran RD.Vertical Diplopia following Cataract surgery- A case report. Asian J Ophthalmol 2009; 11: 116-18</li>
               <li>Venkatesh R, Sengupta S. Non Penetrating Glaucoma Surgery – A Review. Tamil Nadu J Ophthalmology. 2009; 47: 86-95
               </li>
               <li><strong><u>Sengupta S,</u></strong> Venkatesh R, Ravindran RD. Optic Disc Entrance Coloboma – The Broken Disc anomaly. Online publication –Ophthalmic Surg Lasers Imaging (OSLI) – doi: 10.3928/15428877-20100215-97</li>
               <li>Gupta A, Kaliaperumal S, <strong><u>Sengupta S,</u></strong> Babu R. Bilateral Cataract following Lightening injury. European J Ophthalmol 2006: 16; 624-626.</li>
               <li>Sengupta S. Reply to Luck et al – Eye 2010; 24: 1407-1408 </li>
               <li><strong><u>Sengupta S,</u></strong> Reddy PR, Gyatsho J, Ravindran RD, Krishnan KT, Vaidee V. Risk factors for intraocular penetration of caterpillar hair in Ophthalmia Nodosa -A retrospective analysis –Indian J Ophthalmol: 2010; 58:540-543</li>
               <li>Raman R, Sengupta S. Epidemiology of Diabetic Retinopathy – A Review. Kerala J Ophthalmology. 2010; December Issue.</li>
               <li><strong><u>Sengupta S,</u></strong> Rajan S, Reddy PR, Krishnan T, Ravindran RD, Lalitha P, Vaitilingam MC. Comparative study on the Incidence and Outcomes of Pigmented versus Non Pigmented Keratomycosis. Indian J Ophthalmol. 2011; 59: 291-6</li>
               <li>Khetan V, Sengupta S. Twenty year trends of Retinoblastoma under five hospital based cancer registries – Few Observations. Indian J of Cancer.2011;48:371</li>
               <li>John, S, Venkatesan, G. ; Manikandan, N. ; Mohan, T.R. ; Badrinath, S.S. ; Sengupta, S. Implementing electronic medical records at outreach eye camps – A one year experience running title: EMR in eye camps. Medical Informatics and Telemedicine (ICMIT) 2013, March: 29-32. Print ISBN: 978-1-4673-5840-8, Digital Object Identifier: 1109/IndianCMIT.2013.6529403</li>
               <li>Rishi P, Sengupta S. 20Guage Sutureless vitrectomy – A Review. World Journal of Retina and Vitreous, May-August 2011; 1: 37-42.</li>
               <li><strong><u>Sengupta S,</u></strong> Ravindran RD, Veena K, Tamrakar V. Simultaneous bilateral optic disc edema and cataract associated with Albright hereditary osteodystrophy. Middle East Afr J Ophthalmol. 2012; 19: 166-8</li>
               <li>Sengupta S. January 2012 archives web quiz winner. Arch Ophthalmol. 2012; 130(6):810.</li>
               <li><strong><u>Sengupta S,</u></strong> Radke N, Khetan V, Gopal L. Malignant Melanoma – A Review. Karnataka J Ophthalmology. 2011; 28 (3): 15 – 21</li>
               <li>Ravindran RD, Venkatesh R, Chang DF, Sengupta S. Reply to “Reducing endophthalmitis in India: An example of the importance of critical appraisal” – Indian J Ophthalmol – 2011; 59: 412 – 14</li>
               <li>Sengupta S. Reply to “Safety and efficacy of using off Label Bevacizumab versus Mitomycin C to Prevent Bleb Failure in a Single Site Phaco trabeculectomy by a Randomized Controlled Clinical Trial”. J Glaucoma. 2013 Mar;22(3):266-7</li>
               <li><strong><u>Sengupta S,</u></strong> Tomar VPS, Biswas J. Coexistent miliary tuberculosis of choroid and tubercular panuveitis – A Report. Indian J Ophthalmol. 2013;61(12):761-2
               </li>
               <li>Biswas J, Babu A, Sengupta S. Algorithm of Managing a Case of Choroiditis. Bengal Ophthalmic journal. 2012, December: 7 – 10.</li>
               <li>Chaikitmongkol V, Leeungurasatien T, Sengupta S. Work-related Eye Injuries: Important Occupational Health Problem in Northern Thailand. Asia Pac J Ophthalmol (Phila). 2015 May-Jun;4(3):155-60</li>
               <li>Deb A, Kaliaperumal S, Sengupta S. Relationship between systemic hypertension, ocular perfusion pressure and glaucoma – a comparative study in an adult Indian population. Indian J Ophthalmol. 2014;62(9):917-22</li>
               <li><strong><u>Sengupta S,</u></strong> Dhanapal P, Nath M, Haripriya A, Venkatesh R. Goats eye integrated with human nucleus as a training model for phacoemulsification. Indian J Ophthalmol. 2015 Mar;63(3):275-7.</li>
               <li>Kavitha S, Patel SR, Mohini P, Venkatesh R, Sengupta S. Vitiligo iridis and glaucoma – A rare sequelae of Small pox. EYE. 2015 Oct;29(10):1392-4</li>
               <li><strong><u>Sengupta S,</u></strong> Surti R, Vasavada D. Comment on Comments on “Sensitivity and Specificity of SD-OCT in Detecting Idiopathic Polypoidal Choroidal Vasculopathy”. Am J Ophthalmol, 2015; 160(1):203-4.</li>
               <li>Sindal MD, Nakhwa C, Sengupta S. Comparison of sutured vs. sutureless scleral fixated intra ocular lens – A retrospective analysis. J Cataract Refract Surg.  20
               </li>
               <li>16 Jan;42(1):27-34</li>
               <li>Bhatia K, <strong><u>Sengupta S,</u></strong> Sharma S. Spontaneous Extrusion of Subconjunctival Cysticercosis Cyst. JAMA Ophthalmol. 2016 Apr;134(4):e155025.
               </li>
               <li>Bhandari S, <strong><u>Sengupta S,</u></strong> Rajagopalan J, Yadalla D, Kushwaha S, Velis GB, Talele D. Factors affecting treatment outcome in Congenital Nasolacrimal Duct Obstruction– A Retrospective Analysis from South India. Indian J Ophthalmol. 2015 Oct;63:759-62
               </li>
               <li>Zebardast N; Kavitha S, Palaniswamy K, <strong><u>Sengupta S,</u></strong> Kader MA, Raman G, Reddy S, Ramulu PY, Venkatesh R. Angle closure phenotypes in siblings of patients at different stages of angle closure. Ophthalmology. 2016 Jul;123(7):1622-4.</li>
               <li>Sindal MD, Nakhwa C, Sengupta S. Author reply to “Comparison of sutured vs. sutureless scleral fixated intra ocular lens – A retrospective analysis”. J Cataract Refract Surg 2016; 42:27–34.</li>
               <li><strong><u>Sengupta S,</u></strong> Venkatesh R, Krishnamurthy P, Nath M, Mashruwala A, Ramulu PY, Robin AL, Lee P. Intraocular pressure reduction following Phacoemulsification vs. manual small incision cataract surgery: A randomized controlled trial. Ophthalmology. 2016 Aug;123(8):1695-703.</li>
               <li><strong><u>Sengupta S,</u></strong> Pan U, Khetan V. Adult Onset Retinoblastoma – A review. Indian J Ophthalmol. 2016; 2016 Jul;64(7):485-91</li>
               <li>Kumaragurupari R, <strong><u>Sengupta S,</u></strong> Bhandari S. Publication rates from the All India Ophthalmic Conference 2010 compared to 2000 – Are we improving?. Indian J Ophthalmol. 2016;64:722-6.</li>
               <li>Varadaraj V, <strong><u>Sengupta S,</u></strong> Palaniswamy K, Srinivasan K, Kader MA, Raman G, Reddy S, Ramulu PY, Venkatesh R. Evaluation of angle closure as a risk factor for reduced corneal endothelial cell density. J Glaucoma. 2017 Jun;26(6):566-570.</li>
               <li><strong><u>Sengupta S,</u></strong> Pan U. Combined branch retinal vein and branch retinal artery occlusion – clinical features, systemic associations and outcomes. Indian J Ophthalmol. 2017;65:238-41.</li>
               <li>Davila JR, <strong><u>Sengupta S,</u></strong> Niziol NM, Sindal MD, Besirli C, Upadhyaya U, Woodward MA, Venkatesh R, Grubbs J, Jr., Newman-Casey PA. Predictors of Photographic Quality with a Handheld Non-Mydriatic Fundus Camera used for Screening of Vision Threatening Diabetic Retinopathy. Ophthalmologica. 2017;238(1-2):89-99.</li>
               <li>Vengadesan N, Ahmad M, Sindal MD, Sengupta S. Delayed Follow-Up in Patients with Diabetic Retinopathy in South India: Social Factors and Impact on Disease Progression. Indian J Ophthalmol. In Press</li>
               <li>Venkatesh R, <strong><u>Sengupta S,</u></strong> Bhandari S, Mimouni M. Safety and efficacy of CO2 Laser Assisted Sclerectomy Surgery (CLASS) combined with cataract surgery for open angle glaucoma – A preliminary report. DOS Times. In Press</li>
               <li><strong><u>Sengupta S,</u></strong> Honavar SG. Publication ethics. Indian J Ophthalmol 2017;65:429-32</li>
               <li>Sindal MD, <strong><u>Sengupta S,</u></strong> Vasavada D, Balamurugan S. Retained intraocular iron foreign body presenting with acute retinal necrosis. Indian J Ophthalmol. 2017 Oct;65(10):1036-1038.</li>
               <li>Bhatia K, <strong><u>Sengupta S,</u></strong> Bhadauria M. The Learning Curve in External DCR – A Trainees Perspective. Nepal J Ophthalmol. In press</li>
               <li>Khetan V, Sengupta S. What a reviewer wants?. Indian J Ophthalmol 2017;65:656-7.
               </li>
               <li><strong><u>Sengupta S,</u></strong> Sindal MD, Besirli CG, Upadhyaya S, Venkatesh R, Niziol LM, Robin AL, Woodward MA, Newman-Casey Screening for Vision Threatening Diabetic Retinopathy in a South Indian Sample: comparing portable non-mydriatic and standard fundus cameras and clinical exam. Eye. In Press</li>
               <li>Vasavada D, <strong><u>Sengupta S,</u></strong> Prajapati VK, Patel S. Incidence and risk factors of retinopathy of prematurity in Western India – Report from A Regional Institute of Ophthalmology. Nepalese journal of ophthalmology : a biannual peer-reviewed academic journal of the Nepal Ophthalmic Society : NEPJOPH. 2017; 9(18):112-1120.</li>
               <li>Lalitha P, <strong><u>Sengupta S,</u></strong> Ravindran RD, Sharma S, Joseph J, Ambiya V, Das T. A literature review and update on the incidence and microbiology spectrum of postcataract surgery endophthalmitis over past two decades in India. Indian journal of ophthalmology. 2017; 65(8):673-677.</li>
               <li>Sengupta S. Vision assessment in regional Indian languages. Indian journal of ophthalmology (Editorial). 2018; 66(5):617-618.
               </li>
               <li><strong><u>Sengupta S,</u></strong> Vasavada D, Pan U, Sindal M. Factors predicting response of pseudophakic cystoid macular edema to topical steroids and nepafenac. Indian journal of ophthalmology. 2018; 66(6):827-830.
               </li>
               <li>Sengupta S. Comment on the ESCRS PREMED study. J Cataract Refract Surg. 2018 Aug;44(8):1056-1057.
               </li>
               <li>Madhivanan N, <strong><u>Sengupta S,</u></strong> Sindal M, N. Pratheeba Devi, Kumar MA, Ariga M. Comparative analysis of Retropupillary Iris Claw vs. Scleral Fixated intraocular lens in the management of post cataract Aphakia. Indian J Ophthalmol. 2019 Jan;67(1):59-63.
               </li>
               <li><strong><u>Sengupta S,</u></strong> Sindal MD, Baskaran P, Pan U, Venkatesh R. Sensitivity and Specificity of Smartphone-Based Retinal Imaging for Diabetic Retinopathy – A Comparative Study. Ophthalmol Retina. 2019 Feb;3(2):146-153.</li>
               <li>Sengupta S. A perspective on the evolving field of vitreoretinal diseases. Indian J Ophthalmol. 2018 Dec;66(12):1668-1670.</li>
               <li>Velis G, Venkatesh R, Kavitha S, Zabardast N, Sengupta S. Comparison of corrected intraocular pressure by Tonopachy with that of Glodmann Applanation tonometry in normal and glaucomatous patients. Indian journal of ophthalmology. In Press
               </li>
               <li>Sindal MD, Sengupta S. Safety of self-sealing 20-gauge incisions for intrascleral fixation of intraocular lenses. J Cataract Refract Surg. April 2020</li>
               <li>Indian Journal of Ophthalmology 68(5)Sengupta et al. All India Ophthalmological Society -Indian Journal of Ophthalmology consensus statement on preferred practices during the COVID-19, May 2020</li>
               <li>Bhavana Sosale, Aravind R Sosale, Hemanth Murthy, Sabyasachi Sengupta et al. Medios- An offline, smartphone-based artificial intelligence algorithm for the diagnosis of diabetic retinopathy. Indian J Ophthalmol – February 2020.</li>
               <li>Madhivanan Nivean, Pratheeba Devi Nivean, Jagadeesh Kumar Reddy, Kavithaa Ramamoorthy, Sengupta S et al. Performance of a New-Generation Extended Depth of Focus Intraocular Lens-A Prospective Comparative Study. Asia</li>
               <li>Sengupta S (Editorial). Imaging the pediatric retina – Where are we headed?. Indian J Ophthalmol 2019;67:723-4</li>
               </ul>
               My Pubmed page:
               <p><a href="#">https://www.ncbi.nlm.nih.gov/sites/myncbi/sabyasachi.sengupta.1/bibliography/40231615/public/?sort=date&direction=descending</a></p>
          </div>
       </div>
    </div>
 </section>