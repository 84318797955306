<div class="main-wrapper">

    <div class="content" style="background: #f5f5f5;padding: 50px 0;">
       <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-6">
                <img src="assets/user/assets/img/login.jpg" class="d-block w-100">
            </div>
            <div class="col-md-6 login-right">
               <div class="row justify-content-center mt-5">
                  <div class="col-md-9">
                     <div class="login-header">
                        <h5 class="text-center mb-4">Forgot Password</h5>
                         <!-- <h3>Login <a href="instructors-login.php">Are you a Instructor?</a></h3> -->
                         <form #emailForm="ngForm" (ngSubmit)="emailVerify(emailForm.value)">
                           <input type="email" class="form-control mt-4" email="true" placeholder="Email" required ngModel #email="ngModel" name="email">
                           <div class="error" *ngIf="email.errors && email.touched">
                             <p class="error-forms" *ngIf="email.errors?.required">Email is required</p>
                             <p class="error-forms" *ngIf="email.errors?.email">Email format required</p>
                           </div>
                         <input type="submit" class="button button-primary button-login w-100">
                         <p class="text-center mt-2">Already A Member ? <a routerLink="/login" style="font-size: 17px; color: #48421f;">Login</a></p>
                        </form>
                        </div>
                  </div>
               </div>
            </div>
          </div>
       </div>
    </div>
</div>