<!-- ======= Footer ======= -->
<footer id="footer">
    <div class="footer-top">
       <div class="container">
          <div class="row">
             <div class="col-lg-5 col-md-6 footer-contact">
                <h3>DR SENGUPTA</h3>
                <p style="text-align: justify;">
                   Dr Sengupta is a practicing vitreoretinal surgeon based in Mumbai. He has gained formal training in research methodology at Johns Hopkins University, Baltimore, USA.
                </p>
                <strong>Phone:</strong> 9943909766<br>
                <strong>Email:</strong> info@senguptasresearchacademy.com<br>
                <div class="social-links text-lg-right pt-3">
                   <a href="https://www.facebook.com/drsunny1980/" class="twitter"><i class="bx bxl-twitter"></i></a>
                   <a href="https://twitter.com/drsunny1980/" class="facebook"><i class="bx bxl-facebook"></i></a>
                   <a href="https://www.instagram.com/drsunny1980/" class="instagram"><i class="bx bxl-instagram"></i></a>
                </div>
             </div>
             <div class="col-lg-3 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                   <li><i class="bx bx-chevron-right"></i> <a routerLink="contact-us">CONTACT</a></li>
                   <li><i class="bx bx-chevron-right"></i> <a routerLink="term-condition">TERMS OF SERVICE</a></li>
                   <li><i class="bx bx-chevron-right"></i> <a routerLink="privacy-policy">PRIVACY POLICY</a></li>
                </ul>
             </div>
             <div class="col-lg-4 col-md-6 footer-newsletter">
                <h4>SOCIAL UPDATES</h4>
                <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fdrsunny1980%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="340" height="250" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
             </div>
          </div>
       </div>
    </div>
    <div class="container d-lg-flex py-3">
       <div class="me-lg-auto text-center text-lg-start">
          <div class="copyright">
             Copyright 2022 | All rights reserved by Dr. Sengupta
          </div>
       </div>
       <div class="social-links text-center text-lg-right pt-3 pt-lg-0">
          <div class="credits">
             <!-- All the links in the footer should remain intact. -->
             <!-- You can delete the links only if you purchased the pro version. -->
             <!-- Licensing information: https://bootstrapmade.com/license/ -->
             <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/flexor-free-multipurpose-bootstrap-template/ -->
             Designed by SAINTELLECT SOLUTIONS
             <div class="fb-customerchat"
               page_id="1180539881969566">
            </div>
          </div>
          
       </div>
    </div>
    <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
  </footer>
  <!-- End Footer -->