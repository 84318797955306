
<section class="top_site_main " style="min-height: 180px; color: #ffffff;background-image:url(https://eduma.thimpress.com/demo-vc/wp-content/themes/eduma/images/bg-page.jpg);">
  <div class="container">
    <div class="row">
      <span class="overlay-top-header" style="background:rgba(0,0,0,0.5);opacity:1;"></span>
    </div>
  </div>
</section>
<section>
    <div class="container">
        <div class="row">
            <h3>{{ course.course_name }}</h3>
            <div class="col-md-3">
                <img src="{{ course.course_image }}" class="course-author-img">
                <p class="course-meta">Teacher</p>
                <p class="course-author">{{ course.instructor }}</p>
            </div>
            <div class="col-md-3">
                <p class="course-meta">Review</p>
                <div class="rating__card__stars">
                  <span class="bi bi-star-fill checked"></span>
                  <span class="bi bi-star-fill checked"></span>
                  <span class="fa bi-star-fill checked"></span>
                  <span class="fa bi-star-fill checked"></span>
                  <span class="fa bi-star-fill checked"></span>
                </div>
            </div>
            <!-- <div class="col-md-6">
              <div class="free-course" *ngIf="is_course_taken == false">
                {{ course.price == 0.00 ? 'Free' : course.price }}
                <button class="btn btn-warning btn-sm" *ngIf="course.price != 0.00" (click)="buyCourse(course.id)">Buy This Course</button>
              </div>
            </div> -->
        </div>
        <div class="row">
          <!-- <img src="{{ course.course_image }}" class="auto-heigh-img"> -->
        </div>
        <div class="row" style="margin-top: 40px;">
          <div class="col-md-12">
          <ul class="nav nav-tabs tab-overview" id="myTab" role="tablist">
            
            <li class="nav-item" role="presentation">
              <a class="nav-link active" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">
                <i class="bi bi-book check-icom-overview"></i>
                <span>
                  Curriculum
                </span>
              </a>
            </li>

            <li class="nav-item" role="presentation">
              <a class="nav-link" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">
                <i class="bi bi-bookmark-check check-icom-overview"></i>
                <span>
                  Overview
                </span>
              </a>
            </li>

            <li class="nav-item" role="presentation">
              <a (click)="isLoggedIn()" class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">
                <i class="bi bi-bookmark-plus check-icom-overview"></i>
                <span>
                  Materials
                </span>
              </a>
            </li>

            <li class="nav-item" role="presentation">
              <a (click)="isLoggedIn()" class="nav-link" id="quiz-tab" data-bs-toggle="tab" data-bs-target="#quiz" type="button" role="tab" aria-controls="quiz" aria-selected="false"><i class="bi bi-question-circle check-icom-overview"></i>
                <span> 
                  Quizzes
                </span>
              </a>
            </li>

          </ul>
          <div class="tab-content" id="myTabContent">
            
            <div class="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
              <div class="row">
                <div class="col-md-12">
                  <ul class="thim-course-info">
                    <li *ngFor="let topics of course.get_topics | keyvalue">
                      <i class='bx bxs-video-plus bx-fade-left check-icom-overview' style="font-size: 18px;"></i>
                      
                      <a class="lesson-title" style="cursor: pointer;" (click)="playVideo(topics.value.id, topics.key)">
                        {{ topics.value.title }}
                        <i class="bi bi-eye lesson-preview"></i>
                      </a>
                      <span class="value">
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="tab-pane fade" id="home" role="tabpanel" aria-labelledby="home-tab">
              <div class="row">
                <div class="col-md-9">
                  <div class="thim-course-content">
                    <h5>OVERVIEW</h5>
                    <p>
                      {{ course.course_description }}
                    </p>
                  </div>
                </div>
                <div class="col-md-3" style="padding-left: 30px;">
                  <h5>Course Features</h5>
                  <ul class="thim-course-info">
                    <li><i class="bi bi-file-earmark-pdf check-icom-overview"></i>
                      <span class="label">Lectures</span>
                      <span class="value">{{ course.features.no_of_lectures }}</span>
                    </li>
                    <li><i class="bi bi-puzzle check-icom-overview"></i>
                      <span class="label"> Quizzes</span><span class="value">
                        {{ course.features.no_of_quizzes }}
                      </span>
                      </li>
                    <li><i class="bi bi-clock check-icom-overview"></i>
                      <span class="label"> 
                        Duration
                      </span>
                      <span class="value">
                        {{ course.features.duration }}
                      </span>
                    </li>
                    <li><i class="bi bi-arrow-bar-up check-icom-overview"></i>
                      <span class="label"> Skill level</span><span class="value">
                        {{ course.features.skill_level }}
                      </span>
                    </li>
                    <li><i class="bi bi-language check-icom-overview"></i>
                      <span class="label"> Language</span>
                      <span class="value">English</span></li>
                    <li><i class="bi bi-file-earmark-person check-icom-overview"></i>
                      <span class="label"> Students</span><span class="value">
                        {{ course.features.students }}
                      </span>
                    </li>
                    <li><i class="bi bi-check-square check-icom-overview"></i>
                      <span class="label"> 
                        Assessments
                      </span>
                      <span class="value">
                        {{ course.features.assessments }}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            
            <div class="tab-pane fade" id="{{ is_course_taken ? 'contact' : ''  }}" role="tabpanel" aria-labelledby="contact-tab">
              <div class="row">
                <div class="col-md-6" *ngFor="let topic of course.get_topics">
                  <div *ngIf="topic.pdf_material !=Null">
                    <a href="{{ topic.pdf_material }}">
                      <i class="bi bi-file-earmark-pdf check-icom-overview"></i>
                      <span class="label">{{  topic.title }}</span>
                    </a>
                  </div>
                </div>

                <div class="col-md-6" *ngIf="course.course_name == 'Module 3: Biostatistics'">
                  <div>
                    <a href="https://senguptasresearchacademy.com/admin/public/admin/images/pdfimage/Biostatistics_Basics_Advanced.pdf">
                      <i class="bi bi-file-earmark-pdf check-icom-overview"></i>
                      <span class="label">Biostatistics_Basics_Advanced.pdf</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="{{ is_course_taken ? 'quiz' : ''  }}" role="tabpanel" aria-labelledby="quiz-tab">
              <div *ngIf="course.quizz_link !=''">
                <p>click on below link..</p>
                <a href="{{ course.quizz_link }}" class="badge bg-success">{{ course.quizz_link }}</a>
              </div>

              <div *ngIf="course.quizz_link ==''">
                <a href="{{ course.quizz_link }}" class="badge bg-danger">No Quizz Found For This module</a>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
           <ul class="thim-social-share">
             <li>Share</li>
             <li>
              <div>
                  <a href="https://www.facebook.com/drsunny1980/">
                    <i class="bi bi-facebook"></i>
                  </a>
             </div>
            </li>
            <li>
              <div>
                <a href="https://www.instagram.com/drsunny1980/">
                  <i class="bx bxl-instagram"></i>
                </a>
              </div>
            </li>
            <li>
              <div>
                <a href="https://twitter.com/drsunny1980/">
                  <i class="bi bi-twitter"></i>
                </a>
              </div>
            </li>
           </ul>
          </div>
        </div>
      </div>
    </section>
          

