<section class="top_site_main " style="min-height: 180px; color: #ffffff;background-image:url(https://eduma.thimpress.com/demo-vc/wp-content/themes/eduma/images/bg-page.jpg);">
    <div class="container">
      <div class="row">
        <span class="overlay-top-header" style="background:rgba(0,0,0,0.5);opacity:1;"></span>
      </div>
    </div>
  </section>
  <section>
      <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h5>NOTICE: On May 13th , 2017, Sengupta’s Research Academy adopted Terms of Service, providing as follows</h5>
                <p>Welcome to Sengupta’s Research Academy (will be further referred to as SRA). Please read these Terms of Service (“TOS”) and the SRA Privacy Policy prior to registering for senguptasresearchacademy.com or using any portion of the SRA website (the “Site,” which consists of all content and pages located within the senguptasresearchacademy.com web domain), including accessing any course material, or other electronic services. These TOS, and the Privacy Policy are agreements (the “Agreements”) between you and SRA. By using the Site, you accept and agree to be legally bound by the Agreements, whether or not you are a registered user. If you do not understand or do not wish to be bound by the terms of the Agreements, you should not use the Site.</p>
                <p>SRA reserves the right to modify these TOS at any time without advance notice. Any changes to these TOS will be effective immediately upon posting on this page, with an updated effective date. By accessing the Site after any changes have been made, you signify your agreement on a prospective basis to the modified TOS and all of the changes. Be sure to return to this page periodically to ensure familiarity with the most current version of these TOS.</p>
                <h5>DESCRIPTION OF SRA:</h5>
                <p>SRA offers an e-learning portal that consists of 5 modules and a total of 22 video lectures pertaining to various aspects of research methodology. SRA also offers services to assist with design and conduct of a clinical study, data maintenance and analysis and manuscript writing. SRA restricts its services for the medical industry only.
                </p>
                <h5>RULES FOR ONLINE CONDUCT</h5>
                <p>You agree that you are responsible for your own use of the Site and for your User Postings. “User Postings” include all content submitted, posted, published or distributed on the Site by you or other users of the Site, including but not limited to all forum posts, questions, and comments for each lecture. You agree that you will use the Site in compliance with these TOS, and all applicable local, state, national and international laws, rules and regulations, including copyright laws, any laws regarding the transmission of technical data exported from your country of residence, and all Indian and international export control laws.

                </p>
                <p>As a condition of your use of the SRA services, you will not use the Site in any manner intended to damage, disable, overburden or impair any SRA server or the network(s) connected to any SRA server or to interfere with any other party’s use and enjoyment of the Site. You may not attempt to gain unauthorized access to the Site, other accounts, computer systems or networks connected to any SRA server through hacking, password mining or any other means. You may not obtain or attempt to obtain any materials or information stored on the Site, its servers or associated computers through any means not intentionally made available through the Site.

                </p>
                <p><b>The following list of items is strictly prohibited on the site:</b>

                </p>
                <ul>
                    <li>Content that defames, harasses or threatens others;
                    </li>
                    <li>Content that infringes SRA’s intellectual property, including, but not limited to, content and copyrights;</li>
                    <li>Obscene, indecent or unlawful content;</li>
                    <li>Advertising or any form of commercial solicitation; or content related to partisan political activities;
                    </li>
                    <li>Viruses, trojan horses, worms, time bombs, corrupted files, malware, spyware or any other similar software that may damage the operation of another’s computer or property; and
                    </li>
                    <li>Content that contains intentionally inaccurate information or that is posted with the intent of misleading others.
                    </li>
                </ul>
                <p>Furthermore, you agree not to scrape, or otherwise download in bulk, any Site content, including but not limited to a list or directory of users on the system, video lectures, user postings or user information. You agree not to misrepresent or attempt to misrepresent your identity while using the Site.
                </p>
                <h5>USER ACCOUNTS AND AUTHORITY</h5>
                <p>In order to participate fully in Site activities, you must provide your name, an email address and a user password in order to create a user account (“User Account”). By enrolling in an SRA course, you agree that you will maintain only one user account and not let anyone else use your username and/or password. You agree that you will never divulge or share access or access information for your User Account with any third party for any reason. In setting up your User Account, you may be prompted to enter additional optional information (e.g., your address). You represent that all information provided by you is accurate and current. You agree to maintain and update your information to keep it accurate and current. In case you are found to share user details (username and password) with third parties, you will be liable to pay damages to SRA as per Indian criminal penal code laws.
                </p>
                <p>We care about the confidentiality and security of your personal information. Please see our Privacy Policy for more information about what information about you SRA collects and how SRA uses that information.
                </p>
                <h5>YOUR RIGHT TO USE CONTENT ON THE SITE
                </h5>
                <P>Unless indicated as being in the public domain, the content on the Site is protected by Indian and foreign copyright laws. Unless otherwise expressly stated on the Site, the texts, videos, images and other instructional materials provided with the courses offered on this Site are for your personal use in connection with those courses only.
                </P>
                <P>You agree to retain all copyright and other notices on any content you obtain from the Site. All rights in the Site and its content, if not expressly granted, are reserved. Any public display of the video lectures, including conferences, medical and other educational programs, governmental or non-governmental organizations etc. without prior permission from SRA, is strictly prohibited and is liable for damages to SRA if these copyright infringements are found. Commercial distribution of the video lectures without consent and prior written permission from SRA is strictly prohibited.                </P>
                <h5>USER POSTINGS
                </h5>
                <p><b>User Postings Representations and Warranties.</b> By submitting or distributing your User Postings such as comments and reviews for video lectures, you affirm, represent and warrant (1) that you have the necessary rights, licenses, consents and/or permissions to reproduce and publish the User Postings and to authorize SRA and its users to reproduce, modify, publish and otherwise use and distribute your User Postings in a manner consistent with the licenses granted by you below, and (2) that neither your submission of your User Postings nor the exercise of the licenses granted below will infringe or violate the rights of any third party. You, and not SRA, are solely responsible for your User Postings and the consequences of posting or publishing them.</p>
                    <p>License Grant to SRA. By submitting or distributing your User Postings, you hereby grant to SRA a worldwide, non-exclusive, transferable, assignable, sub licensable, fully paid-up, royalty-free, perpetual, irrevocable right and license to host, transfer, display, perform, reproduce, modify, distribute, re-distribute, relicense and otherwise use, make available and exploit your User Postings, in whole or in part, in any form and in any media formats and through any media channels (now known or hereafter developed).
                    </p>
                    <p>
                        License Grant to SRA Users. By submitting or distributing your User Postings, you hereby grant to each user of the Site a non-exclusive license to access and use your User Postings in connection with their use of the Site for their own personal purposes.
                    </p>
                    <p><b>Registration and Payment.</b> Subject to the foregoing, you may be required to pay a fee to watch certain courses on the Site, in which case, the following processes and requirements shall apply:</p>
             <ul>
                 <li>The processing of your payment information is done by an SRA third party vendor, and you will be re-routed to a secure website to complete the payment transaction. The privacy statement posted at the payment landing page operated by such third party vendor will govern the submission and processing of your payment details. Please familiarize yourself with those terms prior to initiating a transaction.</li>
                 <li>Once your payment transaction is completed, you will be re-routed back to an SRA confirmation page and will receive a confirmation email. Please retain this email for your records. If you seek an invoice or receipt for your purchase, please send in a requisition at info@senguptasresearchacademy.com with the Subject “Receipt requisition”.
                </li>
             </ul>
             <p><b>Course Withdrawal and Refunds.
            </b></p>
            <ul>
                <li>You will have a period of 5 days after your payment during which you may withdraw or unregister from the courses. Please include your full name and details of purchase such as date of purchase, modules purchased, online banking transaction ID and amount paid in your email request. Providing a reason for refund is mandatory. SRA will evaluate the authenticity of the refund request and will send you a reply regarding its decision to refund. Please note that dissatisfaction with the quality of the video lectures is not a valid reason for a refund requisition.
                </li>
                <li>Refunds will be credited to the account you used when you made the payment and may take up to two billing cycles to process. SRA has no obligation to issue a refund after the Drop Period, but if you believe a refund is warranted, please email us at <a href="mailto:info@senguptasresearchacademy.com">info@senguptasresearchacademy.com.</a></li>
            </ul>
            <h5>DISCLAIMERS OF WARRANTY / LIMITATIONS OF LIABILITIES</h5>
            <p>The site and any information, content or services made available on the site are provided without warranty of any kind (express, implied or otherwise), including, without limitation, any implied warranties of merchantability, fitness for a particular purpose and non-infringement, except insofar as any such implied warranties may not be disclaimed under applicable law.
                SRA does not warrant that the site will operate in an uninterrupted or error-free manner, that the site is free of viruses or other harmful components, or that the courses or content provided will meet your needs or expectations. SRA also makes no warranty that any particular courses or content will continue to be made available.
                Use of the site, and the content and services obtained from or through the site, are at your own risk. Your access to or download of information, materials or data through the site or any reference sites is at your own discretion and risk, and you will be solely responsible for any damage to your property (including your computer system) or loss of data that results from the download or use of such material or data, unless otherwise expressly provided for in the SRA PRIVACY POLICY.
                </p>
                <p><b>User Postings Disclaimer: </b>You understand that when using the Site you will be exposed to User Postings and comments from a variety of sources and that neither SRA nor the SRA Participants are responsible for the accuracy, usefulness, reliability or intellectual property rights of or relating to such User Postings. You further understand and acknowledge that you may be exposed to User Postings that are inaccurate, offensive, defamatory, indecent or objectionable and you agree to waive, and hereby do waive, any legal or equitable rights or remedies you have or may have against SRA with respect thereto. SRA does not endorse any User Postings or any opinion, recommendation or advice expressed therein. SRA does not have any obligation to monitor any User Postings or any other user communications through the Site.
                    However, SRA reserves the right to review User Postings and to exercise its sole discretion to edit or remove, in whole or in part, any User Posting at any time and for any reason. Without limiting the foregoing, upon receiving notice from a user that a User Posting allegedly does not conform to these TOS, SRA may investigate the allegation and determine in its sole discretion whether to remove the User Posting, which it reserves the right to do at any time and without notice. Please send in your requests for investigating “User postings to info@senguptasresearchacademy.com with the subject “Investigate Comments”
                    
                    </p>
                    <P><b>Links to Other Sites:</b> The Site may include hyperlinks to sites maintained or controlled by others. SRA is not responsible for and does not routinely screen, approve, review or endorse the contents of or use of any of the products or services that may be offered at these sites. If you decide to access linked third-party websites, you do so at your own risk.</P>
                    <p><b>To the fullest extent permitted by applicable law, you agree that SRA will not be liable to you for any loss or damages, either actual or consequential, arising out of or relating to these terms of service, or your (or any third party’s) use of or inability to use the site, or your placement of content on the site, or your reliance upon information obtained from or through the site, whether your claim is based in contract, tort, statutory or other law.
                        In particular, to the fullest extent permitted by applicable law, SRA will not have any liability for any consequential, indirect, punitive, special, exemplary or incidental damages, whether foreseeable or unforeseeable and whether or not SRA has been negligent or otherwise at fault (including, but not limited to, claims for defamation, errors, loss of profits, loss of data or interruption in availability of data).
                        
                        </b></p>
                        <h5>INDEMNIFICATION</h5>
                        <p>You agree to defend, hold harmless and indemnify SRA, and their respective subsidiaries, affiliates, officers, faculty, students, fellows, governing board members, agents and employees from and against any third-party claims, actions or demands arising out of, resulting from or in any way related to your use of the Site, including any liability or expense arising from any and all claims, losses, damages (actual and consequential), suits, judgments, litigation costs and attorneys’ fees, of every kind and nature. In particular, SRA does not guarantee publication of your manuscript in the journal of your choice or any other journal. SRA will provide recommendations for submissions but will not submit any paper on your behalf.
                        </p>
                        <h5>TERMINATION RIGHTS; DISCONTINUATION OF COURSES AND CONTENT:</h5>
                        <p>You agree that SRA, in its sole discretion, may terminate your use of the Site or your participation in it, for any reason or no reason, upon notice to you. It is SRA’s policy to terminate in appropriate circumstances the accounts of users of the Site who are repeat copyright infringers. SRA reserves the right at any time in their sole discretion to cancel, delay, reschedule or alter the format of any course offered through SRA, or to cease providing any part or all of the Site content or related services, and you agree that SRA will not have any liability to you for such an action. If you no longer desire to participate in the Site, you may terminate your participation at any time. The rights granted to you hereunder will terminate upon any termination of your right to use the Site, but the other provisions of these TOS will survive any such termination.
                        </p>
                        <p><b>Entire Agreement:</b>These TOS, and the Privacy Policy together constitute the entire agreement between you and SRA with respect to your use of the Site, superseding any prior agreements between you and SRA regarding your use of the Site.</p>
                        <p>Waiver and Severability of TOS. The failure of SRA to exercise or enforce any right or provision of these TOS shall not constitute a waiver of such right or provision. If any provision of these TOS is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties’ intentions as reflected in the provision and the other provisions of these TOS shall remain in full force and effect.</p>
                        <p><b>Choice of Law/Forum Selection.</b> You agree that these TOS and any claim or dispute arising out of or relating to these TOS or any content or service obtained from or through the Site will be governed by the laws of India, excluding its conflicts of law provisions. You agree that all such claims and disputes will be heard and resolved exclusively in the courts located in the city of Mumbai, Maharashtra, India. You consent to the personal jurisdiction of those courts over you for this purpose, and you waive and agree not to assert any objection to such proceedings in those courts (including any defense or objection of lack of proper jurisdiction or venue or inconvenience of forum).</p>
                   <h5>VIOLATIONS
                </h5>
                If you are found in violation of the Terms of Service, you may be subject to one or more of the following actions:
                <br>
                Termination of your use of the Site.
                <br>
                Additional actions may be taken at the sole discretion of SRA.
                <br>
                No refunds will be issued in the case of any corrective action for such violations.
                <p><b>Effective Date:</b> May 13, 2017</p>

                    </div>
        </div>
      </div>
  </section>