import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-singleblog',
  templateUrl: './singleblog.component.html',
  styleUrls: ['./singleblog.component.css']
})
export class SingleblogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
