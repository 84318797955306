<div class="main-wrapper">

    <div class="content" style="background: #f5f5f5;padding: 50px 0;">
       <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-6">
                <img src="assets/user/assets/img/login.jpg" class="d-block w-100">
            </div>
            <div class="col-md-6 login-right">
               <div class="row justify-content-center">
                  <div class="col-md-8">
                     <div class="login-header">
                        <h5 class="text-center mb-4">Login with your site account</h5>
                         <!-- <h3>Login <a href="instructors-login.php">Are you a Instructor?</a></h3> -->
                         <form #loginForm="ngForm" (ngSubmit)="login(loginForm.value)">
                           <input type="email" class="form-control mt-4" email="true" placeholder="Email" required ngModel #email="ngModel" name="email">
                           <div class="error" *ngIf="email.errors && email.touched">
                             <p class="error-forms" *ngIf="email.errors?.required">Email is required</p>
                             <p class="error-forms" *ngIf="email.errors?.email">Email format required</p>
                           </div>
                            <input type="password" class="form-control mt-4" placeholder="Password" required ngModel #password="ngModel" name="password">
                            <div class="error" *ngIf="password.invalid && password.touched">
                              <p class="error-forms">Password is required</p>
                           </div>
                           <input type="text" class="form-control mt-4" placeholder="{{ firstRandomNumber }} + {{ secondRandomNumber }}" (change)="validateCaptcha($event)">
                           <div class="error" *ngIf="validation == false">
                             <p class="error-forms">Wrong Answer</p>
                           </div>
                         <div class="mt-2"><a (click)="redirectToEmailVerification()" style="cursor: pointer;">Lost your password?</a></div>
                         <input type="submit" value="Submit" class="button button-primary button-login w-100">

                        </form>
                        </div>
                        <!-- <img src="{{ user.photoUrl }}">
                           <div>
                           <h4>{{ user.name }}</h4>
                           <p>{{ user.email }}</p>
                           <button (click)="signOut()">sign out</button>
                        </div> -->
                        <!-- <a href="javascript::void(0)" (click)="signInWithGoogle()" class="google-login  mt-2">
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path d="M21.8055 10.0415H21V10H12V14H17.6515C16.827 16.3285 14.6115 18 12 18C8.6865 18 6 15.3135 6 12C6 8.6865 8.6865 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C6.4775 2 2 6.4775 2 12C2 17.5225 6.4775 22 12 22C17.5225 22 22 17.5225 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z" fill="#FFC107" />
                              <path d="M3.15295 7.3455L6.43845 9.755C7.32745 7.554 9.48045 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C8.15895 2 4.82795 4.1685 3.15295 7.3455Z" fill="#FF3D00" />
                              <path d="M12 22C14.583 22 16.93 21.0115 18.7045 19.404L15.6095 16.785C14.5718 17.5742 13.3038 18.001 12 18C9.39904 18 7.19053 16.3415 6.35853 14.027L3.09753 16.5395C4.75253 19.778 8.11354 22 12 22Z" fill="#4CAF50" />
                              <path d="M21.8055 10.0415H21V10H12V14H17.6515C17.2571 15.1082 16.5467 16.0766 15.608 16.7855L15.6095 16.7845L18.7045 19.4035C18.4855 19.6025 22 17 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z" fill="#1976D2" />
                           </svg>
                           GOOGLE
                        </a> -->
                        <!-- <button class="gsi-material-button google-login" (click)="signInWithGoogle()">
                          <div class="gsi-material-button-state"></div>
                          <div class="gsi-material-button-content-wrapper">
                            <div class="gsi-material-button-icon">
                              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlns:xlink="http://www.w3.org/1999/xlink" style="display: block;">
                                <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                                <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                                <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                                <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                                <path fill="none" d="M0 0h48v48H0z"></path>
                              </svg>
                            </div>
                            <span class="gsi-material-button-contents">Sign in with Google</span>
                            <span style="display: none;">Sign in with Google</span>
                          </div>
                        </button> -->
                       <div id="google-btn"  style="margin-top: 10px;"></div>

                        <a href="javascript::void(0)" (click)="signInWithFB()" class="facebook-login mt-2">
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path d="M13.397 20.997V12.801H16.162L16.573 9.59199H13.397V7.54799C13.397 6.62199 13.655 5.98799 14.984 5.98799H16.668V3.12699C15.8487 3.03918 15.0251 2.99678 14.201 2.99999C11.757 2.99999 10.079 4.49199 10.079 7.23099V9.58599H7.33203V12.795H10.085V20.997H13.397Z" fill="#fff" />
                           </svg>
                           FACEBOOK
                        </a>
                        <p class="text-center mt-2">Don’t have an account? <a routerLink="/register" style="font-size: 17px; color: #48421f;">Register</a></p>
                  </div>
               </div>
            </div>
          </div>
       </div>
    </div>
</div>
